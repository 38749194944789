import typing from "../../images/typing.jpg";
import interview from "../../images/interview.jpg";
import clockup from "../../images/clockup.jpg";
import damageDetection from "../../images/damageDetection.jpg";
import Interview from "./Interview";
import QuickTyping from "./QuickTyping";
import ClockUp from "./ClockUp";
import DamageDetection from "./DamageDetection";

const pocarry = [
  {
    title: "Quick Typing: Your AI Conversation Partner",
    description:
      "Welcome to Quick Typing, an innovative platform designed to revolutionize the way you interact with AI. Similar to ChatGPT, Gemini, or Copilot, Quick Typing offers seamless conversational experiences powered by ChatGPT-4. Whether you’re looking to generate content, engage in meaningful conversations, or utilize voice search, Quick Typing has you covered. ",
    coverImage: typing,
    pocURL: "https://dev-quick-typings.llmconnect.ai/",
    pocCustomDetailsPage: function () {
      return <QuickTyping gotoLink={this.pocURL} imageLink={this.coverImage} />;
    },
    faq: [
      {
        question: "What is Quick Typing?",
        answer:
          "Quick Typing is an AI-powered platform that allows users to engage in natural conversations with an AI bot, similar to ChatGPT, Gemini, or Copilot. It supports text and voice interactions and is powered by ChatGPT-4.",
      },
      {
        question: "How do I create an account on Quick Typing?",
        answer:
          "Visit the Quick Typing website and click on the ‘Sign Up’ button. Fill in the required details and verify your email to activate your account.",
      },
      {
        question: "Can I customize the AI’s responses?",
        answer:
          "Yes, Quick Typing allows you to create and add custom prompts. Navigate to the ‘Custom Prompts’ section, enter your instructions for ChatGPT-4, and save the prompts for future use.",
      },
      {
        question: "How do I use the voice search feature?",
        answer:
          "Enable the microphone on your device, click on the ‘Voice Search’ icon on the Quick Typing platform, and start speaking your queries to the AI bot.",
      },
      {
        question: "Is my data secure on Quick Typing?",
        answer:
          "Absolutely. We prioritize your privacy and ensure that all interactions are secure and confidential. We use advanced encryption methods to protect your data.",
      },
    ],
  },
  {
    coverImage: interview,
    title: "Interview It",
    description:
      "Our innovative Codeshare platform is designed to streamline the interview process for companies, facilitate team-based coding projects, and provide educational tools for programming instruction. By enabling real-time collaboration, this platform redefines how businesses conduct technical interviews and how developers engage in coding tasks, both for team projects and educational purposes.",

    pocURL: "https://codeshare.llmconnect.ai/",
    pocCustomDetailsPage: function () {
      return <Interview gotoLink={this.pocURL} imageLink={this.coverImage} />;
    },
    faq: [
      {
        question: "What is Interview IT?",
        answer:
          "Interview IT is a platform designed to streamline technical interviews, facilitate team-based coding projects, and provide educational tools for programming instruction. It enables real-time collaboration, making it ideal for remote developer interviews, team projects, and classroom learning.",
      },
      {
        question: "Who can benefit from using Interview IT?",
        ulfaq: [
          "Companies conducting technical interviews",
          "Developers collaborating on coding projects",
          "Educators teaching programming concepts",
          "Students learning programming through real-time interaction",
        ],
      },
      {
        question: "What are the key features of Interview IT?",
        ulfaq: [
          "ollaborative coding environment for real-time team projects",
          "Remote technical interviews with real-time coding assessments",
          "Educational tool for live coding sessions in classroom settings",
          "Predefined coding templates and challenges for interviews and learning",
        ],
      },
      {
        question: "How do I set up a coding session on Interview IT?",
        ulfaq: [
          "Step 1: Sign up for an account using your professional email.",
          "Step 2: Open the coding editor from the dashboard.",
          "Step 3: Use the 'Share' button to invite team members or interviewees via a shareable link.",
        ],
      },
      {
        question: "How does Interview IT support remote technical interviews?",
        answer:
          "Interviewers can set up coding tasks, share them with candidates, and observe the candidates as they solve challenges in real-time. This allows for more practical assessments compared to traditional whiteboard interviews.",
      },
    ],
  },
  {
    coverImage: clockup,
    title:
      "ClockUp - Simplifying Project Management and Team Collaboration for Companies",
    description:
      "ClockUp is an all-in-one platform designed to help companies manage their projects and teams with ease. It simplifies the entire process of assigning tasks, tracking project progress, and ensuring seamless communication within a single workspace. ClockUp empowers companies to stay organized, monitor team productivity, and complete projects efficiently, regardless of size. ",

    pocURL: "/",
    pocCustomDetailsPage: function () {
      return <ClockUp gotoLink={this.pocURL} imageLink={this.coverImage} />;
    },
    faq: [
      {
        question: "What is ClockUp?",
        answer:
          "ClockUp is a project management platform designed to help companies manage their projects, assign tasks to team members, track progress, and collaborate in real-time. It simplifies the workflow by allowing you to create workspaces, monitor tasks, and keep team members notified about their responsibilities.",
      },
      {
        question: "How does ClockUp help with project management?",
        answer:
          "ClockUp provides tools to create project listings, assign tasks to team members, track their progress, monitor time spent on each task, and manage team collaboration. This makes it easy to organize work, meet deadlines, and stay informed about project developments.",
      },
      {
        question: "Can I assign multiple teams to different projects?",
        answer:
          "Yes, ClockUp allows you to create separate workspaces for different projects and assign unique teams to each project. You can switch between workspaces, track progress, and ensure each team is working on their assigned tasks.",
      },
      {
        question: "How do notifications work on ClockUp?",
        answer:
          "When you assign tasks to team members or make changes to the project, ClockUp sends real-time notifications to keep everyone informed. This ensures that no team member misses out on their responsibilities or project updates.",
      },
      {
        question: "Can I track how much time is spent on tasks?",
        answer:
          "Yes, ClockUp has built-in time-tracking features that allow you to monitor how long each team member takes to complete a specific task. This helps in managing resources effectively and understanding team productivity.",
      },
    ],
  },
  {
    coverImage: damageDetection,
    title: "Damage Detection Platform",
    description:
      "The Damage Detection Platform leverages cutting-edge AI technology to assist users in identifying and analysing damage to their furniture. This platform provides a user-friendly interface where customers can upload images of their furniture, and our AI-powered system analyses the image to detect various types of damage. With integration from leading AI platforms like OpenAI, Gemini, and Claude, our solution delivers an accurate and comprehensive damage overview.",

    pocURL: "https://damage-detect.llmconnect.ai/",
    pocCustomDetailsPage: function () {
      return (
        <DamageDetection gotoLink={this.pocURL} imageLink={this.coverImage} />
      );
    },
    faq: [
      {
        question: "What is the Damage Detection Platform?",
        answer:
          "The Damage Detection Platform is an AI-powered tool that helps users detect and analyze damage in furniture. By uploading an image of the damaged furniture, our platform provides insights on the material, colour, type of damage, and more through advanced AI integration with OpenAI, Gemini, and Claude.",
      },
      {
        question: "How does the platform work?",
        answer:
          "It’s simple! Just upload a clear image of the damaged furniture and click “Detect.” Our system will analyse the image using three AI platforms and provide a detailed report, including the material, colour, type of damage, and where it’s located on the furniture.",
      },
      {
        question: "What kind of damage can the platform detect?",
        ulfaq: [
          "External Damage (e.g., surface scratches)",
          "Stains or discoloration",
          "Specific Damage (e.g., colour loss, peeling, scratches)",
          "Damage Area (e.g., drawer, frame, top)",
        ],
      },
      {
        question: "How accurate is the damage detection?",
        answer:
          "We use a combination of OpenAI, Gemini, and Claude, three of the most advanced AI platforms, to provide highly accurate detection. The system analyses the image using AI models designed to assess various attributes like material, colour, and damage type.",
      },
      {
        question: "Can the platform tell me how the damage happened?",
        answer:
          "While the platform mainly focuses on detecting and categorizing damage, you can provide input about when and how the damage occurred. This information helps in generating a more comprehensive report.",
      },
    ],
  },
];

export default pocarry;
